<template>
	<div>
		
		<div v-if="artist" class="container">
			<h1 class="heading">{{artist.name}}</h1>
			<div class="row">
				<div class="col-6 col-md-4 col-lg-3 artist " id="">
						
						<img v-if="artist.imageURL" :src="artist.imageURL" style="width:100%;" class="card-img-top">
						<img v-else src="../assets/no-user-image.jpg" style="width:100%;" class="card-img-top">
						<!--<div class="card-body">
							<h3 v-html="artist.name" class="card-title mt-auto text-center"></h3>
						</div>-->
						<div class="row">
							<div class="card-footer col-12 text-center">
								
								<button class="btn btn-block btn-heit-purp mt-auto" data-cat="watch-button" :data-id="artist.id">View Video</button>

								


							</div>
						</div>
				</div>
				<div class="col-6 col-md-8 col-lg-9 artist" id="">
					<div class="row">
						<div class="col-12"><h3 class="label-heading">{{artist.name}} Music Videos</h3></div>
						<div v-for="video in artistMusicVideos" class="col-4">
							<div class="card ytvid">
								<!--<h4 class="new"><span class="badge badge-secondary" v-if="isNew()">{{video.genre}}</span></h4>-->
								<!--<h4 class="genre"><span class="badge badge-secondary" >{{video.genre}}</span></h4>-->
								<!-- <div class='card-img-top embed-container'><iframe :src="'https://www.youtube.com/embed/' + video.ytid" frameborder='0' allowfullscreen></iframe></div> -->
								<img class="card-img-top" :src="'https://img.youtube.com/vi/' + video.ytid + '/maxresdefault.jpg'" data-cat="thumbnail" @click="log" :alt="video.title">
								<div class="card-body d-flex flex-column">
									<h5 class="card-title mt-auto video-title">{{video.title}} by {{artist.name}}</h5>
									<h5> Featuring <span v-if="video.features" v-html="formatArrayWithCommas(video.features)"></span><span v-else></span></h5>

									<p class="card-text mt-auto">Released on <span v-html="formatDate(video.timestamp.seconds)"></span>.</p>
									<button class="btn btn-block btn-heit-purp mt-auto" data-cat="watch-button" @click="viewVideo($event)" :alt="video.title" :data-id="video.id">View Video</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>

		<div v-else><NotFound /></div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Vuex from 'vuex'
	import Header from './Header.vue'
	import NotFound from './NotFound.vue'
	import VueRouter from 'vue-router'
	import mixin from '../helpers/mixin.js'

	Vue.use(VueRouter);


	export default {
		name: "Artist",
		components: { Header, NotFound },
		mixins: [mixin],
		data(){
			return{
				title:"",
				name: "",
				genre: "",
				description: "",
				imageURL: "",
				URL: "",
				features: [],
				formattedFeatures: ""
			}
		},
		head: {
			title: function () {
		      return {
		        inner: this.title
		      }
		    },
		    meta: function (){
		    	return [
					{ name: 'description', content: this.description, id: "description" }, // id to replace intead of create element

					// Facebook / Open Graph
					{ property: 'og:title', content: this.title, id: "og-title"},
					{ property: 'og:description', content: this.description, id: "og-description"},

					{ property: 'og:image', content: this.imageURL, id: "og-image"},
					{ property: 'og:url', content: this.URL, id: "og-url"},
					{ property: 'og:image:width', content: "1280", id:"og-image-width"},
					{ property: 'og:image:height', content: "720", id:"og-image-height"},
					//backup og-image
					{ property: 'og:image', content: "https://www.heitmusic.com/images/HTG-New-Rap-R&B-Music-Videos.png", id: "og-image"}


					
					// with shorthand
					//{ p: 'og:image', c: 'https://example.com/image.jpg' }
			    ]
			}
		},
		computed: {
			...Vuex.mapState(['artist']),
			...Vuex.mapState(['artistMusicVideos']),
		},
		created(){
			this.$store.dispatch('getArtist', this.$route.params.id).then(( v =>{
				//console.log(this.musicVideo)
			}));
		}

	}


</script>

<style scoped>
	.label-heading{
		display:inline;
		padding-right: 10px;
		margin-top:auto;
		margin-bottom:auto;
	}
</style>