export default{
	methods:{
		formatDate: function(date){

			const monthNames = ["January", "February", "March", "April", "May", "June",
				"July", "August", "September", "October", "November", "December"
			];

			var d = new Date(date * 1000);

			var stringDate = monthNames[d.getUTCMonth()] + " " + d.getUTCDate() + ", " + d.getUTCFullYear();
			return stringDate;
		},

		formatArrayWithCommas: function(array){
			var output;
			if(array && array.length){
				array.forEach(function(item, i){
				if(i == 0){
					output = item;
				}else if(i = array.length -1 && array.length == 2){
					output = output + " & " + item;
				}else if(i = array.length - 1){
					output = output + ", & " + item;
				}
			});
			}

			return output;
		}
	}
}