<template>
	
	<div id="navigation" class="">
		

			<!--<div class="branding">
				<router-link to="/"><img alt="Heit The Great Website" src="../assets/heit-the-great-logo.png" @click="logHome" id="logo"></router-link>
			</div>-->

			<div id="menu" class="row">
				<router-link to="/music-videos"><i class="fas fa-video" @click="log" alt="Music Videos" title="Watch Music Videos"></i></router-link> &nbsp;
			</div>
	</div>
			 <!--<router-link to="/login">Login</router-link> &nbsp; -->
				
				<!--<router-link to="/user"><i class="fas fa-user" @click="log" alt="User" title="User Profile"></i></router-link> &nbsp;
				<router-link to="/artists"><i class="fas fa-id-card" @click="log" alt="Artists" title="Browse Artists"></i></router-link> &nbsp;-->

			 	<!-- <router-link to="/private-videos">Videos</router-link> &nbsp; -->
			 	<!--<router-link to="/heit-beats">Beats</router-link> &nbsp;-->
			 	<!-- <router-link to="/user">User</router-link> -->	 	
	
		

</template>

<script>
	export default {
		name: 'Navigation',
		methods:{
			log: function(e){
			/*this.$ga.event({
				eventCategory: 'social-link',
				eventAction: 'click',
				eventLabel: e.target.getAttribute('alt')
			})*/
		},
		}
	}
</script>

<style scoped>

	#logo{
		height:40px;
	}

	a{
			color: #42b983;
		}


	#social-icons{
			
		}

		#navigation i{
			font-size:6vh;
		}

		#social-icons  i{
			font-size:2.3vh;
			color:#EBA90D;
			text-shadow: 0.1vh 0.1vh #52194D;
		}


		.fab, .fas{
			padding-left:3px;
			padding-right:3px;
		}

	.branding{
	    margin-left:auto;
	    margin-right:auto;
	}

	#menu{
		position:absolute;
		right:0;
	}

	#navigation{
		position: -webkit-sticky;
		position:sticky;
		top:0px;
		z-index:9000;
		background-color: rgba(255, 255, 255, 1);
		width:100%;
	}

</style>