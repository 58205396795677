<template>
	<div id="musicVideosMini" class="row">
		<div v-for="video in musicVideosMini" class="col-sm-6 col-lg-3 d-flex align-items-stretch video-mini">
			<!--v-bind:style="{ 'background-image': 'url(https://img.youtube.com/vi/' + video.ytid + '/maxresdefault.jpg)' }"-->
			<div class="card ytvid"  @click="viewVideo($event, video.id)" :alt="video.title">
				<h4 class="genre"><span class="badge badge-secondary">{{video.genre}}</span></h4>
				<img class="card-img-top" :src="'https://img.youtube.com/vi/' + video.ytid + '/maxresdefault.jpg'" :alt="video.title">
				<div class="card-info">
					<h5 class="date badge-mini" v-html="formatDate(video.timestamp.seconds)"></h5>
					<h5 class="badge-mini video-title">{{video.title}}</h5>
					<h5 class="badge-mini video-info"><span class="badge-name">{{video.artist}}</span> <span v-if="video.features"> feat. </span><span v-if="video.features" v-html="formatArrayWithCommas(video.features)"></span></h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Vuex from 'vuex'
	import mixin from '../../helpers/mixin.js'
	import { db } from '../../main'

	export default {
		name: "MusicVideosMini",
		mixins: [mixin],
		data(){
			return{
				
			}
		},
		props:{
			total:{
				type: Number,
				required: true
			}
		},
		computed: {
			...Vuex.mapState(['musicVideosMini']),
		},
		created(){
			var total = this.$props.total;

			this.$store.dispatch('getMusicVideosMini', {
				ref: db.collection('musicvideos'),
				limit: this.$props.total
			}).then(mv =>{
				
			});
		},
		methods: {
			log: function(){

			},
			viewVideo: function(e, id){
				console.log(e);
				this.$ga.event({
					eventCategory: "music-video-mini",
					eventAction: 'click',
					eventLabel: id
				})
				this.$router.push('/music-videos/' + id);
			},
		}
	}
</script>

<style scoped>
	.genre {
		position:absolute;
	}
	.date, .video-info{
		
		color:white;
	}

	.video-title{

	}
	.card-body{
		padding:3px 3px 3px 3px;
		text-align:center;
	}

	.badge-mini{
		margin:0;
		border-radius:0px;
		text-align:right;
		font-size:2.5vh;
		padding-right:5px;
	}

	.gold{
		background-color:;
	}

	.card{
		border:none;
		background-color:#52194D;
		cursor: pointer;
	}

	.video-mini{
		background-repeat: no-repeat;
  		background-attachment: fixed;
  		background-position: center;
  		background-size: cover;
	}

	.card-info{
		position: absolute;
  		bottom: 0px;
  		width:100%;
  		height:35%;
  		background: rgba(82, 25, 77, 0.8);
  		padding-top: 5px;
		padding-bottom: 5px;
	}
</style>