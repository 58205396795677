<template>
	<div>
		<Header />
		<div v-if="rappers" class="container">
			<h1 class="heading">Rappers/Emcees</h1>
			<div class="row">
				<div class="input-group input-group-lg offset-1 col-sm-10" id="rapper-serach">

					  <div class="input-group-prepend">
					    <span class="input-group-text" id="inputGroup-sizing-lg"><i class="fas fa-search"></i></span>
					  </div>
					  <input type="text" v-model="search" class="form-control" aria-label="Search" aria-describedby="inputGroup-sizing-sm">
				</div>

				<div v-for="rapper in filteredRappers" class="col-xs-4 col-md-3 rapper card" id="the-rappers">
					
					<img v-if="rapper.imageURL" :src="rapper.imageURL" style="width:100%;" class="card-img-top">
					<img v-else src="../assets/no-user-image.jpg" style="width:100%;" class="card-img-top">
					<h3 v-html="rapper.name" class="card-title mt-auto text-center"></h3>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vuex from 'vuex'
	import Header from './Header.vue'
	import { db } from '../main'

	export default {
	 	name: 'Rappers',
	 	components: {Header},
		//mixins: [mixin],
		data(){
			return{
				search:''
			}
		},
		computed: {
			...Vuex.mapState(['rappers']),
			filteredRappers: function(){
				return this.rappers.filter((rapper) => {
					return rapper.name.match(this.search) || rapper.firstName.match(this.search) || rapper.lastName.match(this.search);
				})
			}

		},
		created(){
			this.$store.dispatch('getRappers', db.collection('artists'))
		},
	}
</script>

<style scoped>
	.rapper{
		width:25%;
		margin-bottom:10px;
	}

	#rapper-search{
		
	}

	#the-rappers{
		margin-top:15px;
	}

	#inputGroup-sizing-lg{
		background-color:#EBA90D;
		color:#52194D;
	}

</style>