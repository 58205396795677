<template lang="html">
	<div id="login" class="container">
		<Header />
		<h1 class="heading">Please Login.</h1>
		<div id="firebaseui-auth-container"></div>
	</div>
</template>

<script>

	import firebase from 'firebase/app';
	import firebaseui from 'firebaseui'
	import {firebaseConfig} from '../helpers/firebaseConfig';
	import { db } from '../main'
	import Header from './Header.vue';

	export default {
		name: 'login',
		components: {
		   Header
		},
		mounted() {
			var uiConfig = {
				signInSuccessUrl: '/user',
				signInOptions: [
					firebase.auth.EmailAuthProvider.PROVIDER_ID,
					firebase.auth.FacebookAuthProvider.PROVIDER_ID,
					firebase.auth.GoogleAuthProvider.PROVIDER_ID,
					//firebase.auth.PhoneAuthProvider.PROVIDER_ID
				],
				callbacks:{
					signInSuccessWithAuth: function(result, redirectUrl){

						alert("callback");

						console.log(result);
						var u = result.user;
						var userRef = db.collection('users').doc(u.uid);

						var getDoc = userRef.get().then(doc => {
					        if (!doc.exists) {
					            console.log('No such document!');
					            var setWithMerge = userRef.set({
									displayName: u.displayName,
									email: u.email,
									firstName: "",
									lastName: "",
									nickname:"",
									phone: u.phoneNumber,
									photoURL: u.photoURL,
									uid: u.uid,
									roles:{
										general: true
									},
									providerData: u.providerData
								}, { merge: true });
					        } else {
					            console.log('Document data:', doc.data());
					        }
					    })
					    .catch(err => {
					        console.log('Error getting document', err);
					    });
							
						
					}
				}
				
			};
			var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
			ui.start('#firebaseui-auth-container', uiConfig);
		}
	}
</script>