<template>
	<div id="private-videos" class="container">
		<Header />
		<h1 class="heading">Private Heit Videos</h1>

	</div>
</template>

<script>
	import Header from './Header.vue'

	export default{
		name: "PrivateVideos",
		components: {
           Header
        }
	}
</script>