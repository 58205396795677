<template>
	<div class="container">
		<h1 class="heading">Add New Music Video</h1>
		<form @submit.prevent>
			<div class="form-group row">
				<label for="" class="col-sm-2 col-form-label">Artist</label>
				<div class="col-sm-6">
					<input type="text" class="form-control" id="artist" placeholder="Artist Name" v-model="newVideo.artist">
				</div>
			</div>
			<div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">Song Title</label>
				<div class="col-sm-6">
					<input type="text" class="form-control" id="title" placeholder="Song Title" v-model="newVideo.title">
				</div>
			</div>

			<div class="form-group row">
				<label for="exampleFormControlSelect2" class="col-sm-2 col-form-label">Genre</label>
				<div class="col-sm-6">
					<select class="form-control" id="genreSelect" v-model="newVideo.genre">
						<option>Rap</option>
						<option>R&B</option>
					</select>
				</div>
			  </div>
			
			<div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">Release Date</label>
				<div class="col-sm-6">
					<input type="date" class="form-control" id="date" placeholder="" v-model="newVideo.date" @change="transformDate()">
				</div>
			</div>
			<div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">YT ID</label>
				<div class="col-sm-6">
					<input type="text" class="form-control" id="ytid" placeholder="ytid" v-model="newVideo.ytid">
				</div>
			</div>

			<div id="features-input" class="row">Features?
				<div class="col-sm-1">
					<button class="btn btn-heit-purp"  @click="addFeature()"><i class="fas fa-plus-square"></i></button>
				</div>
				<div class='col-sm-3' v-for="(feature, i) in newVideo.features">
					<input type='text' class='form-control feature-input' placeholder='Featured Artist' v-model='newVideo.features[i]'>
				</div> 
			</div>

			<div class="row">

					<div class="col-sm-2">
						<div class="switchToggle">
							Show?
							<input class="" type="checkbox" id="showCheck" v-model="newVideo.show">
							<label for="showCheck" class="">Show</label>
						</div>
					</div>
			</div>

			<button class="btn btn-heit-purp"  @click="addVideo()">Submit Video</i></button>
			{{newVideo}}

		</form>
	</div>
</template>

<script>
	import firebase from 'firebase/app'
	import { db } from '../../main'

	export default {
		name:'NewMusicVideo',
		data: function (){
			return{
				newVideo: {
					artist:"",
					title:"",
					date:null,
					timestamp:null,
					ytid:"",
					features:[],
					show:false
				}
			}
		},
		methods:{
			addFeature(){
				this.newVideo.features.push('')
			},

			transformDate(){

				const monthNames = ["January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				];

				var date = new Date(this.newVideo.date);

				var convertedDate = firebase.firestore.Timestamp.fromDate(date);
				this.newVideo.timestamp = convertedDate;

			},

			createVideoId(){
				var artist = this.newVideo.artist;
				var title = this.newVideo.title;

				artist = artist.replace(/[^a-zA-Z0-9]/g,'-').split(' ').join('-');
				artist = artist.split('.').join('');
				title = title.replace(/[^a-zA-Z0-9]/g,'-').split(' ').join('-');
				title = title.split('.').join('');

				var id = title + "-by-" + artist;
				return id;
			},

			addVideo(){

				var ref= db.collection('musicvideos').doc(this.createVideoId());

				if(this.newVideo.features === undefined || this.newVideo.features.length == 0) {
					delete this.newVideo.features;
					var setWithMerge = ref.set(this.newVideo, { merge: true }).then(() =>{
						console.log("new Video added - no features");

						this.newVideo = {
							artist:"",
							title:"",
							genre:"",
							date:null,
							timestamp:null,
							ytid:"",
							features:[],
							show:false
						}

					});
				}else{
					var setWithMerge = ref.set(this.newVideo, { merge: true }).then(() =>{
						console.log("new Video added with features");

						this.newVideo = {
							artist:"",
							title:"",
							genre:"",
							date:null,
							timestamp:null,
							ytid:"",
							features:[],
							show:false
						}

					});
				}
			}
		}


	}
</script>

<style scoped>
	.outerDivFull { margin:50px; } 

	.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
	.switchToggle label {cursor: pointer; text-indent: -9999px; width: 70px; max-width: 70px; height: 30px; background: #d1d1d1; display: block; border-radius: 100px; position: relative; }
	.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 26px; height: 26px; background: #fff; border-radius: 90px; transition: 0.3s; }
	.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #3e98d3; }
	.switchToggle input + label:before, .switchToggle input + input + label:before {content: 'No'; position: absolute; top: 5px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
	.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'Yes'; position: absolute; top: 5px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
	.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
	.switchToggle label:active:after {width: 60px; } 
	.toggle-switchArea { margin: 10px 0 10px 0; }

	form{
		width:75%;
		margin-left:auto;
		margin-right:auto;
	}
</style>