<template>
	<div>
		<Header />
		<div v-if="artists" class="container">
			<h1 class="heading">Artists</h1>
			<div class="row">
				
				<div class="input-group input-group-lg col-12 col-sm-7" id="artist-serach">

					  <div class="input-group-prepend">
						<span class="input-group-text" id="inputGroup-sizing-lg"><i class="fas fa-search"></i></span>
					  </div>
					  <input type="text" v-model="search" class="form-control" aria-label="Search" aria-describedby="inputGroup-sizing-sm">
				</div>

				<div class="col-12 col-sm-5" style="padding:0px;">
					<div class="btn-group btn-group-toggle" data-toggle="buttons">
						<!--<h5 class="label-heading align-bottom">Filter: </h5>-->

						<label class="btn btn-heit-purp active" @click="filterArtists('all')">
							<input type="radio" name="categories" checked>All
						</label>

						<label class="btn btn-heit-purp" @click="filterArtists('rappers')">
							<input type="radio" name="categories">Rappers
						</label>

						<label class="btn btn-heit-purp" @click="filterArtists('producers')">
							<input type="radio" name="categories">Producers
						</label>

						<label class="btn btn-heit-purp" @click="filterArtists('groups')">
							<input type="radio" name="categories">Groups
						</label>

						<label class="btn btn-heit-purp" @click="filterArtists('women')">
							<input type="radio" name="categories">Women
						</label>

					</div>
				</div>
			</div>

			<div class="row">

				<div v-for="artist in searchedArtists" class="col-6 col-md-4 col-lg-3 artist card" id="the-artists">
					
					<img v-if="artist.imageURL" :src="artist.imageURL" style="width:100%;" class="card-img-top">
					<img v-else src="../assets/no-user-image.jpg" style="width:100%;" class="card-img-top">
					<!--<div class="card-body">
						<h3 v-html="artist.name" class="card-title mt-auto text-center"></h3>
					</div>-->
					<div class="row">
						<div v-if="dbUser" class="card-footer col-12 text-center">
							<h5 class="small-heading">{{artist.name}}</h5>
							<button class="btn btn-block btn-heit-purp mt-auto" data-cat="watch-button" @click="viewArtist($event)" :data-id="artist.id">View Video</button>


							<div v-if="dbUser.artistRatings[artist.id]">
							<vue-stars @input="newGeneralRating($event, artist.id)" :name="artist.id" :active-color="ratingSettings.active" :inactive-color="ratingSettings.inactive" class="fire-rating col-md-12"
								:shadow-color="ratingSettings.shadow" :hover-color="ratingSettings.hover" :max="ratingSettings.max" :value="userGeneralRating(dbUser.artistRatings[artist.id], artist.id)">

								
								<span slot="activeLabel"><i class="fas fa-fire activeFlame"></i></span>
								<span slot="inactiveLabel" ><i class="fas fa-fire inactiveFlame"></i></span>

							</vue-stars>
							</div>

							<div v-else>
							<vue-stars @input="newGeneralRating($event, artist.id)" :name="artist.id" :active-color="ratingSettings.active" :inactive-color="ratingSettings.inactive" :shadow-color="ratingSettings.shadow"
								:hover-color="ratingSettings.hover" :max="ratingSettings.max" :value="userGeneralRating(dbUser.artistRatings[artist.id], artist.id)" class="fire-rating col-md-12">

								
								<span slot="activeLabel"><i class="fas fa-fire activeFlame"></i></span>
								<span slot="inactiveLabel" ><i class="fas fa-fire inactiveFlame"></i></span>

							</vue-stars>
							</div>
							{{dbUser.artistRatings[artist.id]}}


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vuex from 'vuex'
	import firebase from 'firebase/app'
	import Header from './Header.vue'
	import { db } from '../main'
	import { store } from '../store/store'
	import { VueStars } from 'vue-stars'

	export default {
		name: 'Artists',
		components: {Header, VueStars},
		//mixins: [mixin],
		data(){
			return{
				search:'',
				category: 'all',
				user: {},
				isRatingUpdate: false,
				ratingSettings: {
					active: "#EBA90D",
					inactive: "#999999",
					showdow: "#ccc",
					hover: "#ccc",
					max: 5,
					readonly: false
				}
			}
		},
		computed: {
			...Vuex.mapState(['artists']),
			...Vuex.mapState(['dbUser']),
			searchedArtists: function(){
				return this.artists.filter((artist) => {

					var searched = artist.name.match(this.search) || artist.name.toUpperCase().match(this.search) || artist.name.toLowerCase().match(this.search)
						|| artist.firstName.match(this.search) || artist.firstName.toUpperCase().match(this.search) || artist.firstName.toLowerCase().match(this.search)
						|| artist.lastName.match(this.search) || artist.id.toUpperCase().match(this.search) || artist.id.toLowerCase().match(this.search);

						//TODO:  fix lastName filter above

					if (searched && this.category == "women"){
						return artist.isFemale;
					}else if(searched && this.category =="groups"){
						return artist.isGroup;
					}else if(searched && this.category =="producers"){
						return artist.isProducer
					}else if(searched && this.category =="rappers"){
						return artist.isRapper;
					}else if(searched && this.category == "all"){
						return true;
					}
				})
			},
			categorized: function(){

				return this.artists.filter((artist) => {
						return artist.isProducer;
				})
			}

		},
		created(){
			this.$store.dispatch('getArtists', db.collection('artists'));

			this.user = firebase.auth().currentUser;
			if(this.user){
				/*this.$store.dispatch('getUser', this.user.uid).then((r, re) =>{
					
				});*/
			}
			console.log(store.state.dbUser);
		},
		methods: {
			filterArtists: function(cat){
				this.category = cat;
			},

			userGeneralRating: function(rating, id){
				
				if(rating){
					//console.log(id);
					//console.log(rating);
					return rating.general;
				}else{
					return 0;
				}
			},

			isUpdate: function(current){

			},
			viewArtist: function(e){
				/*this.$ga.event({
					eventCategory: e.target.getAttribute('data-cat'),
					eventAction: 'click',
					eventLabel: e.target.getAttribute('alt')
				})*/

				var id = e.target.getAttribute('data-id');
				console.log(id);
				this.$router.push('/artists/' + id);
			},

			newGeneralRating: function(rating, id){

				var userRef= db.collection('users').doc(this.dbUser.uid);
				var artistRef = db.collection('artists').doc(id);

				var mergeUser = userRef.set({
					artistRatings: {
						[id]: {
							general: rating
						}
					}
				}, { merge: true }).then(() =>{

					return db.runTransaction(function(transaction) {
					   // This code may get re-run multiple times if there are conflicts.
					    return transaction.get(artistRef).then(function(aDoc) {
					        if (!aDoc.exists) {
					            throw "Document does not exist!";
					        }
					        // Note: this could be done without a transaction
					        //       by updating  using FieldValue.increment()
					        var newNum = (aDoc.data().numOfGeneralRatings || 0) + 1;
					        var newTotal = (aDoc.data().totalOfGeneralRatings || 0) + rating;

					        transaction.update(artistRef, {
					        	numofGeneralRatings: newNum,
					        	totalOfGeneralRatings: newTotal
					        });
					    });
					}).then(function() {
					    console.log("Transaction successfully committed!");
					}).catch(function(error) {
					    console.log("Transaction failed: ", error);
					}); 

				});
			}
		}


	}

	$(".btn-group > .btn").click(function(){
	    $(this).addClass("active").siblings().removeClass("active");
	});
</script>

<style scoped>
	.artist{
		width:25%;
		margin-bottom:10px;
	}

	#artist-search{
		
	}

	#the-artists{
		margin-top:15px;
	}

	#inputGroup-sizing-lg{
		background-color:#EBA90D;
		color:#52194D;
	}

	.label-heading{
		display:inline;
		padding-right: 10px;
		margin-top:auto;
		margin-bottom:auto;
	}

	.btn-group{
		margin-top:auto;
		margin-bottom:auto;
	}

	.fire-rating{
		font-family: FontAwesome;
		font-style:normal;
		width:auto;
		margin-left:auto;
		margin-right:auto;
	}

	.activeFlame{
		/* color:#EBA90D; */
		font-size:20px;
	}

	.inactiveFlame{
		/* color: rgba(82,25,77,0.1); */
		font-size:20px
	}

</style>