<template>
	<div id="app" class="container-fluid">
		
		<Navigation />
		<Header />
		<AudioPlayer id="audio-player" />
		<router-view></router-view>		

	</div>

</template>

<script>
	import Vue from "vue" 
	import Navigation from './components/Navigation.vue';
	import Header from './components/Header.vue';
	import Home from './components/Home.vue';
	import Playlists from './components/Playlists.vue';
	import VideosYT from './components/VideosYT.vue';
	import Login from './components/Login.vue'
	import User from './components/User.vue'
	import AudioPlayer from './components/AudioPlayer.vue'
	import MusicVideos from './components/MusicVideos.vue'
	import { store } from './store/store'

	import Aplayer from 'vue-aplayer'
	import VueAPlayer from 'vue-aplayer'

	import Vuex from 'vuex'
	import firebase from 'firebase/app'

	VueAPlayer.disableVersionBadge = true

	export default {
		name: 'App',
		components:{
			Navigation,Header, Aplayer, AudioPlayer,Home
		},
		data(){
			return {
				user:null,
			}
		},
		computed:{
			...Vuex.mapState(['dbUser'])
		},
		created(){
			this.user = firebase.auth().currentUser;
			if(this.user){
				this.$store.dispatch('getUser', this.user.uid).then((r, re) =>{
					
				});
			}
		},
		mounted(){
			var height = jQuery('#audio-player').height() + 3;
			jQuery("#app").css('margin-bottom',height);

			jQuery(".aplayer-icon-menu").click(function(){
				console.log("resizing");
				setTimeout(function(){
					//console.log( "something changed");
					var height = jQuery('#audio-player').height() + 3;
					jQuery("#app").css('margin-bottom', height);
				},500);
			   
			});

			jQuery( window ).resize(function() {
				var height = jQuery('#audio-player').height() + 3;
				jQuery("#app").css('margin-bottom', height);
			});
			 document.dispatchEvent(new Event('app.rendered'));
		},
		methods: {
			log: function(e){
				this.$ga.event({
					eventCategory: 'navigation-link',
					eventAction: 'click',
					eventLabel: e.target.getAttribute('alt')
				})
			}
		}
	}

</script>

<style>
	@import 'assets/styles.css';

	

	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
		color: #eba90d;
		margin-top: 10px;
	}

	body{
		background: url("./assets/heit-the-great-audio-cable-connector.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	h1, h3, h4, h5{
		font-family: fira-sans-condensed,sans-serif;
		font-weight: 700;
		font-style: normal;
	}

	.heading{
		background-color:#eba90d;
		padding-top:10px;
		padding-bottom:10px;
		text-transform:uppercase;
		margin-bottom:25px;
		color:white;
		text-align:center;
	}
	.section{
		margin-bottom:20px;
	}

	.btn { border-radius: 0; }

	.btn-heit-gold { 
	  color: #FFFFFF; 
	  background-color: #EBA90D; 
	  border-color: #FFFFFF; 
	} 
	 
	.btn-heit-gold:hover, 
	.btn-heit-gold:focus, 
	.btn-heit-gold:active, 
	.btn-heit-gold.active, 
	.open .dropdown-toggle.btn-heit-gold { 
	  color: #FFFFFF; 
	  background-color: #52194D; 
	  border-color: #FFFFFF; 
	} 
	 
	.btn-heit-gold:active, 
	.btn-heit-gold.active, 
	.open .dropdown-toggle.btn-heit-gold { 
	  background-image: none; 
	} 
	 
	.btn-heit-gold.disabled, 
	.btn-heit-gold[disabled], 
	fieldset[disabled] .btn-heit-gold, 
	.btn-heit-gold.disabled:hover, 
	.btn-heit-gold[disabled]:hover, 
	fieldset[disabled] .btn-heit-gold:hover, 
	.btn-heit-gold.disabled:focus, 
	.btn-heit-gold[disabled]:focus, 
	fieldset[disabled] .btn-heit-gold:focus, 
	.btn-heit-gold.disabled:active, 
	.btn-heit-gold[disabled]:active, 
	fieldset[disabled] .btn-heit-gold:active, 
	.btn-heit-gold.disabled.active, 
	.btn-heit-gold[disabled].active, 
	fieldset[disabled] .btn-heit-gold.active { 
	  background-color: #EBA90D; 
	  border-color: #FFFFFF; 
	} 
	 
	.btn-heit-gold .badge { 
	  color: #EBA90D; 
	  background-color: #FFFFFF; 
	}

	.btn-heit-purp { 
	  color: #FFFFFF; 
	  background-color: #52194D; 
	  border-color: #FFFFFF; 
	} 
	 
	.btn-heit-purp:hover, 
	.btn-heit-purp:focus, 
	.btn-heit-purp:active, 
	.btn-heit-purp.active, 
	.open .dropdown-toggle.btn-heit-purp { 
	  color: #FFFFFF; 
	  background-color: #eba90d; 
	  border-color: #FFFFFF; 
	} 
	 
	.btn-heit-purp:active, 
	.btn-heit-purp.active, 
	.open .dropdown-toggle.btn-heit-purp { 
	  background-image: none; 
	} 
	 
	.btn-heit-purp.disabled, 
	.btn-heit-purp[disabled], 
	fieldset[disabled] .btn-heit-purp, 
	.btn-heit-purp.disabled:hover, 
	.btn-heit-purp[disabled]:hover, 
	fieldset[disabled] .btn-heit-purp:hover, 
	.btn-heit-purp.disabled:focus, 
	.btn-heit-purp[disabled]:focus, 
	fieldset[disabled] .btn-heit-purp:focus, 
	.btn-heit-purp.disabled:active, 
	.btn-heit-purp[disabled]:active, 
	fieldset[disabled] .btn-heit-purp:active, 
	.btn-heit-purp.disabled.active, 
	.btn-heit-purp[disabled].active, 
	fieldset[disabled] .btn-heit-purp.active { 
	  background-color: #52194D; 
	  border-color: #FFFFFF; 
	} 
	 
	.btn-heit-purp .badge { 
	  color: #52194D; 
	  background-color: #FFFFFF; 
	}

	#navigation{
		position: -webkit-sticky;
		position:sticky;
		top:0px;
		z-index:9000;
		background-color: rgba(255, 255, 255, 1);
		width:100%;
		height:6vh;
	}

	#navigation i{
		color: #52194D;
		
	}

	#audio-player{
		z-index:10000;
		bottom:0;
		position:fixed;
		left:0;
		width:100%;
		margin:0;
	}

	#aplayer-main{
		margin-bottom:0;
		margin-left:0;
		margin-right:0;
	}

</style>