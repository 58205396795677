<template>
	<div id="spotify-playlists">

		<div class="row">
			<div class="col-md-12">
			<h1 class="heading">Spotify Playlists</h1>
		</div>
			<div class="col-md-3 col-lg-3 col-xl-3">
				<iframe src="https://open.spotify.com/embed/user/mv1l2eih7mdc7f4y35s0zqeen/playlist/1Ec3oxrgTWmGq5VAjrDbB7" height="340" frameborder="0"></iframe>
			</div>
			<div class="col-md-3 col-lg-3 col-xl-3">
				<iframe src="https://open.spotify.com/embed/user/mv1l2eih7mdc7f4y35s0zqeen/playlist/1vubdep6dLMdw5GDvczTka" height="340" frameborder="0"></iframe>
			</div>
			<div class="col-md-3 col-lg-3 col-xl-3">
				<iframe src="https://open.spotify.com/embed/user/mv1l2eih7mdc7f4y35s0zqeen/playlist/2XGsbyzQYemGRlMIXaJblg" height="340" frameborder="0"></iframe>
			</div>
			<div class="col-md-3 col-lg-3 col-xl-3">
				<iframe src="https://open.spotify.com/embed/user/mv1l2eih7mdc7f4y35s0zqeen/playlist/3UmVvk4dDLhKVyuexqWLQF" height="340" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
			</div>

		</div>
	</div>
</template>

<script>
	export default{
		name: "Playlists"
	}
</script>

<style scoped>
	
	
	
	iframe{
		width:100%;
		display:inline-block;
		margin-left:auto;
		margin-right:auto;
		max-height:30vh;
	}
	h1{
		
	}
</style>