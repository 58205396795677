<template>

	<div id="header">
		<div id="" class="row">
			<div class="col-12 text-center">
				<router-link to="/"><img id="logo" alt="Heit The Great Website" src="../assets/heit-the-great-logo-inline.png" @click="logHome"></router-link>
			</div>

			<div class="offset-lg-4 col-12 col-md-4">
				<div id="social-icons" class="row text-center"> 
					<div class="col-2"><a href="https://www.youtube.com/heitthegreat" target="_blank" @click="log"><i class="fab fa-youtube" alt="YouTube"></i></a></div>
					<div class="col-2"><a href="https://www.patreon.com/heit" target="_blank" @click="log"><i class="fab fa-patreon" alt="Patreon"></i></a></div>
					<div class="col-2"><a href="https://www.instagram.com/heitthegreat" target="_blank" @click="log"><i class="fab fa-instagram" alt="Instagram"></i></a></div>
					<div class="col-2"><a href="https://www.twitter.com/heitmusic" target="_blank" @click="log"><i class="fab fa-twitter-square" alt="Twitter"></i></a></div>
					<div class="col-2"><a href="https://www.facebook.com/heitmusic" target="_blank" @click="log"><i class="fab fa-facebook-square" alt="Facebook"></i></a></div>
					<div class="col-2"><a href="http://eepurl.com/dKUz26" target="_blank" @click="log"><i class="fas fa-envelope-square" alt="Mailing List"></i></a></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'Header',
  props: {
	msg: String
  },
	methods: {
		log: function(e){
			this.$ga.event({
				eventCategory: 'social-link',
				eventAction: 'click',
				eventLabel: e.target.getAttribute('alt')
			})
		},
		logHome:function(e){
			this.$ga.event({
				eventCategory: 'home-link',
				eventAction: 'click',
				eventLabel: "Logo"
			})
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	#header{
		text-align:;
	}

	  h3 {
		margin: 40px 0 0;
	  }
	  ul {
		list-style-type: none;
		padding: 0;
	  }
	  li {
		display: inline-block;
		margin: 0 10px;
	  }
		a{
			color: #42b983;
		}

		#header{
			margin-bottom:60px;
		}

		#social-icons{
			font-size:3.2vh;
			margin-top:15px;
		}

		#social-icons  i{
			color:#EBA90D;
			text-shadow: 0.1vh 0.1vh #52194D;
		}

		.fab, .fas{
			padding-left:3px;
			padding-right:3px;
		}

		#logo{
			max-width:90vw;
			text-align:center;
		}
</style>
