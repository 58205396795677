<template>
  <div id="heit-beats">
	<Header />
	   <div v-for="beat in beats">
		   {{beat.title}} <button @click="addSong(beat)">+</button>
	   </div>
  </div>
</template>

<script>
  import AudioPlayer from './AudioPlayer.vue'
  import Header from './Header.vue'

  export default {
	name: "BeatsPlayer",
	components: {
	  Header, AudioPlayer
	},
	data(){
		return{
			beats: [
				{
					title: 'Master (Instrumental)',
					artist: 'Heit The Great',
					src: '/assets/audio/beats/Master-(Instrumental).mp3',
					pic: '/assets/images/HTGWings.png'
				},
				{
					title: 'Free (Instrumental)',
					artist: 'Heit',
					src: '/assets/audio/beats/Free-Instrumental.mp3',
					pic: '/assets/images/HTGWings.png'
				}
			]
		}
	},
		methods: {
			addSong: function(obj){
			   this.$store.commit('ADD_SONG', obj);
			}
		}
  }
</script>