<template>
	<div class="container">
		<Header />
		<div v-if="dbUser">
			<div v-if="dbUser.roles.admin">
				<NewMusicVideo />
			</div>
		</div>
		
	</div>
</template>

<script>
	import firebase from 'firebase/app'
	import Header from './Header.vue'
	import NewMusicVideo from './Dash//NewMusicVideo.vue'
	import { mapState } from 'vuex'

	export default {
		name:'dash',
		components: {
			Header, NewMusicVideo
		},
		data() {
			return{
				user: {},
				isAdmin: false
			}
		},
		computed: {
			...mapState(['dbUser']),
			getAdmin(){
				console.log("getadmin");
			}
		},
		created(){
			this.user = firebase.auth().currentUser;

			if(this.user){
				this.$store.dispatch('getUser', this.user.uid).then((r, re) =>{
					console.log("in then");
				});
			}

		},
		mounted(){
			console.log(" in dash mounted");
			//this.$router.push('/');
		},
		methods: {
			logOut(){
				firebase.auth().signOut();
			}
		}

	};
</script>

<style>

</style>