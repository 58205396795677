import Vue from 'vue'
import App from './App.vue'
import VideosYT from './components/VideosYT.vue'
import { store } from './store/store'
import Vuex from 'vuex';
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import User from './components/User.vue'
import PrivateVideos from './components/PrivateVideos.vue'
import Aplayer from 'vue-aplayer'
import {firebaseConfig} from './helpers/firebaseConfig'
import VueAnalytics from 'vue-analytics'
import firebase from 'firebase/app'
import firebaseui from 'firebaseui';
import 'firebase/firestore'
import NotFound from './components/NotFound.vue'
import Artists from './components/Artists.vue'
import Artist from './components/Artist.vue'
import Rappers from './components/Rappers.vue'
import BeatsPlayer from './components/BeatsPlayer.vue'
import MusicVideos from './components/MusicVideos.vue'
import MusicVideo from './components/MusicVideo.vue'
import Dash from './components/Dash.vue'

import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import mixin from './helpers/mixin.js'


Vue.config.productionTip = true;
Vue.use(VueHead);

///START OF ROUTER

const routes = [

		{ path: '/', component: Home},
		{ path: '/404', component: NotFound },  
		{ path: '*', redirect: '/404' }, 
		{ path: '/login', component: Login},
		{ path: '/rappers', component: Rappers},
		{ path: '/artists', component: Artists},
		{ path: '/artists/:id', component: Artist},
		{
			path: '/music-videos',
			component: MusicVideos,
			meta: {
		      title: 'Rap/Hip Hop and R&B Videos - Heit The Great',
		      metaTags: [
		        {
		          name: 'description',
		          content: "New Rap/Hip Hop and R&B music videos for the 1990's  & 1980's babies."
		        },
		        {
		          property: 'og:description',
		          content: "New Rap/Hip Hop and R&B music videos for the 1990's  & 1980's babies."
		        }
		      ]
		    }
		},
		{ path: '/music-videos/:id', component: MusicVideo, props:true},
		{ 
			path: '/user',
			component: User,
			beforeEnter: (to, from, next) => {
				const currentUser = firebase.auth().currentUser;

				 if (currentUser) {
					console.log("user is logged in for user");
					setUpNewUser();
					next();
				}  else {
					next('/login');
					
				}
			}
		},
		{ 
			path: '/dash', 
			component: Dash,
			beforeEnter: (to, from, next) => {
				const currentUser = firebase.auth().currentUser;

				 if (currentUser) {
					console.log(" user is logged in for dash");
					next();
				}  else {
					next('/login');
					
				}
			}
		},
		{ 
			path: '/private-videos', 
			component: PrivateVideos,
			beforeEnter: (to, from, next) => {

				const currentUser = firebase.auth().currentUser;
				console.log("before enter private videos.............");
				console.log(requiresAuth);

				 if (currentUser) {
					console.log(" user is logged in for private videos");
					setUpNewUser();
					next();
				}  else {
					next('/login');
					
				}
			}
		},
		{ 
			path: '/heit-beats', 
			component: BeatsPlayer,
			beforeEnter: (to, from, next) => {

				const currentUser = firebase.auth().currentUser;
				console.log(currentUser);

				 if (currentUser) {
					console.log(" user is logged for beats");
					setUpNewUser();
					next();
				}  else {
					next('/login');
					
				}
			}
		}
]

const isProduction = true;

const setUpNewUser = function (){
	var u = firebase.auth().currentUser;
	var userRef = db.collection('users').doc(u.uid);

	var getDoc = userRef.get().then(doc => {
		if (!doc.exists) {
			console.log('No such document!');
			var setWithMerge = userRef.set({
				displayName: u.displayName,
				email: u.email,
				firstName: "",
				lastName: "",
				nickname:"",
				phone: u.phoneNumber,
				photoURL: u.photoURL,
				uid: u.uid,
				roles:{
					general: true
				},
				providerData: u.providerData
			}, { merge: true });
		} else {
			console.log('Document data:', doc.data());
		}
	})
	.catch(err => {
		console.log('Error getting document', err);
	});
}


const router = new VueRouter({
  routes,
  store,
  mode: 'history',
  mounted(){
	console.log("mounted");
  }
});



//END OF ROUTER

let app = ""


Vue.use(VueAnalytics, {
	id: "UA-139901314-1"
})

Vue.use(Aplayer);
Vue.use(store);

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
/*firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });*/



firebase.auth().onAuthStateChanged((user) => {

	if(!app){
		app = new Vue({
			router,
			store,
			mixins: [mixin],
			render: h => h(App),
		}).$mount('#app')
	}

	if(user) {

		//this.$router.push('/user');
		//console.log("user is logged in from main");

		/*var userRef = db.collection('users').doc(user.uid);
			var setWithMerge = userRef.set({
				displayName: user.displayName,
				email: user.email,
				firstName: "",
				lastName: "",
				nickname:"",
				phone: user.phoneNumber,
				photoURL: user.photoURL,
				uid: user.uid,
				roles:{
					general: true
				},
				providerData: user.providerData
			}, { merge: true });*/

			/*var userRef = db.collection('users').doc(user.uid);
			var setWithMerge = userRef.set({
				uid: user.uid,
				roles:{
					general: true
				},
				providerData: user.providerData
			}, { merge: true });*/

	} else {
		//this.$router.push('/');
		//console.log("user not logged in");
	}
});