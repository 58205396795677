import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { firestoreAction } from 'vuexfire'
import { db } from '../main'


Vue.use(Vuex)

export const store = new Vuex.Store({
	state:{
		songQueue: [
			{
				title: 'Heit',
				artist: 'Heit The Great',
				src: '/assets/audio/songs/Heit-HeitTheGreat.mp3',
				pic: '/assets/images/HTGWings.png'
			},
			{
				title: 'Master (Instrumental)',
				artist: 'Heit The Great',
				src: '/assets/audio/beats/Master-(Instrumental).mp3',
				pic: '/assets/images/HTGWings.png'
			}
		],
		dbUser: null,
		musicVideos: [],
		musicVideosMini: [],
		artists: [],
		rappers: [],
		musicVideo: null,
		artist: null,
		artistMusicVideos: []
	},
	mutations:{
		...vuexfireMutations,
		ADD_SONG(state, song) {
			//console.log("mutating data");
			state.songQueue.push(song);
			var height = jQuery('#audio-player').height() + 3;
				jQuery("#app").css('margin-bottom',height);
		}
	},
	actions:{
		loadSongQueue(context, songs){
			
		},
		setMusicVideosRef: firestoreAction(({ bindFirestoreRef }, ref) => {
			bindFirestoreRef('musicVideos', ref.orderBy("timestamp", "desc")).then(mv => {
				//console.log(mv);
				//create Array of ids
				/*var ids = [];
				mv.forEach(function(video){
				  	ids.push('/music-videos/' + video.id);
				});*/

				//console.log(ids);
			})
		}),
		getMusicVideosMini: firestoreAction(({ bindFirestoreRef }, {ref, limit}) => {
			bindFirestoreRef('musicVideosMini', ref.where("show", "==", true).orderBy("timestamp", "desc").limit(limit)).then(mv => {
				
			})
		}),
		getUser: firestoreAction(({ bindFirestoreRef },  ref ) => {
			bindFirestoreRef('dbUser', db.collection('users').doc(ref)).then(dbUser =>{

			})
		}),
		getMusicVideo:firestoreAction(({ bindFirestoreRef },  ref ) => {
			//console.log(ref);
			bindFirestoreRef('musicVideo', db.collection('musicvideos').doc(ref)).then(mv =>{
				//console.log(mv);
			})
		}),
		getArtist:firestoreAction(({ bindFirestoreRef },  ref ) => {
			//console.log(ref);
			bindFirestoreRef('artist', db.collection('artists').doc(ref)).then(mv =>{
				console.log(mv);
				bindFirestoreRef('artistMusicVideos', db.collection('musicvideos').where("artist", "==", mv.name)).then(v =>{
					console.log(v);
				})

			});

			
		}),
		getArtistMusicVideos:firestoreAction(({ bindFirestoreRef },  ref ) => {
			//console.log(ref);
			bindFirestoreRef('musicVideos', ref.where("name", "==", ref).orderBy("timestamp", "desc")).then(mv => {
				//console.log(mv);
				//create Array of ids
				/*var ids = [];
				mv.forEach(function(video){
				  	ids.push('/music-videos/' + video.id);
				});*/

				//console.log(ids);
			})
		}),
		getRappers: firestoreAction(({ bindFirestoreRef }, ref) => {
			bindFirestoreRef('rappers', ref.where("isRapper", "==", true)).then( res =>{
				
			});
		}),
		getArtists: firestoreAction(({ bindFirestoreRef }, ref) => {
			bindFirestoreRef('artists', ref).then( res =>{
				
			});
		}),
	},
	getters:{
		songQueue: state => state.songQueue,
		musicVideos: state => state.musicVideos,
		musicVideosMini: state => state.musicVideosMini,
		artists: state => state.artists,
		rappers: state => state.rappers,
		dbUser: state => state.dbUser,
		musicVideo: state => state.musicVideo,
		artist: state => state.artist,
		artistMusicVideos: state => state.artistMusicVideos
	}
})