<template>
	<div>
	   <aplayer id="aplayer-main"
			theme="#52194d"
			repeat="no-repeat"
			:music='songQueue[0]'
			:list='songQueue'
			listFolded
		  />

	</div>
</template>

<script>
	import Aplayer from 'vue-aplayer'
	import VueAPlayer from 'vue-aplayer'
	VueAPlayer.disableVersionBadge = true

   export default {
		name: "AudioPlayer",
		components: {
		  Aplayer},
		data(){
			return{
				songQueue: this.$store.state.songQueue
			}
		}
	}
</script>