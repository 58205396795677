<template>
	<div id="music-video" class="container" v-if="musicVideo">
		<!--<Header />-->
		<div class="heading" v-if="currentVideo">
			<h1 class="mv-title" :html="musicVideo.title">{{musicVideo.title}} by {{musicVideo.artist}}</h1>
			<h2 class="mv-featuring" v-if="musicVideo.features">Featuring <span v-html="formatArrayWithCommas(musicVideo.features)"></span></h2>
		</div>
		

		<div class="row">
			<div class='card-img-top embed-container'><iframe :src="'https://www.youtube.com/embed/' + musicVideo.ytid" frameborder='0' allowfullscreen></iframe></div>
		</div>

			
		<social-sharing class="social-sharing row justify-content-between" @open="log"
			:url="URL"
            :title="title"
            :description="description"
            :quote="description"
            hashtags="heitthegreat,heitmusic"
            twitter-user="heitmusic"
            inline-template>

        		<div class="col-12">
	                <network network="facebook" class="network btn btn-heit-purp float-left" data-title="video.title">
			        	<i class="fab fa-facebook-square"></i> Share
			      	</network>
			      	<network network="twitter" class="network btn btn-heit-purp float-right">
			        	<i class="fab fa-twitter-square"></i> Share
			      	</network>
		     	 </div>
	    </social-sharing>
		
		<div class="row">
			
		</div>



	</div>
</template>

<script>
	import Vue from 'vue'
	import Vuex from 'vuex'
	import { db } from '../main'
	import Header from './Header.vue'
	import VueRouter from 'vue-router'
	import mixin from '../helpers/mixin.js'
	var SocialSharing = require('vue-social-sharing');

	Vue.use(SocialSharing);

	Vue.use(VueRouter);

	export default {
		name: "MusicVideos",
		components: { Header },
		mixins: [mixin],
		data(){
			return{
				title: "",
				artist: "",
				genre: "",
				description: "",
				imageURL: "",
				URL: "",
				features: [],
				formattedFeatures: ""
			}
		},
		head: {
			title: function () {
		      return {
		        inner: this.title
		      }
		    },
		    meta: function (){
		    	return [
					{ name: 'description', content: this.description, id: "description" }, // id to replace intead of create element

					// Facebook / Open Graph
					{ property: 'og:title', content: this.title, id: "og-title"},
					{ property: 'og:description', content: this.description, id: "og-description"},

					{ property: 'og:image', content: this.imageURL, id: "og-image"},
					{ property: 'og:url', content: this.URL, id: "og-url"},
					{ property: 'og:image:width', content: "1280", id:"og-image-width"},
					{ property: 'og:image:height', content: "720", id:"og-image-height"},
					//backup og-image
					{ property: 'og:image', content: "https://www.heitmusic.com/images/HTG-New-Rap-R&B-Music-Videos.png", id: "og-image"}


					
					// with shorthand
					//{ p: 'og:image', c: 'https://example.com/image.jpg' }
			    ]
			}
		},
		computed: {
			...Vuex.mapState(['musicVideo']),
			currentVideo: function(){
				this.artist = this.musicVideo.artist;
				this.genre = this.musicVideo.genre;
				this.title = this.artist + " - " + this.musicVideo.title + " [Music Video]";
				this.imageURL = 'https://img.youtube.com/vi/' + this.musicVideo.ytid + '/maxresdefault.jpg';
				this.URL = "https://www.heitmusic.com" + this.$router.currentRoute.path + "/";
				this.features = this.musicVideo.features;

				this. formattedFeatures = this.formatFeatures(this.features);

				if(this.features){
					this.description = this.genre + " music video for " + this.musicVideo.title + " by " + this.artist + " featuring " + this.formattedFeatures + " on HeitMusic by Heit The Great";
				}else{
					this.description = this.genre + " music video for " + this.musicVideo.title + " by " + this.artist + " on HeitMusic by Heit The Great";
				}
				
				
				this.$emit('updateHead');
				return this.musicVideo;
			}
		},
		created(){

			this.$on('updatedHead', function () {

			});

			return this.$store.dispatch('getMusicVideo', this.$route.params.id).then(( v =>{
				//console.log(this.musicVideo)
			}));


		},
		methods: {
			getAsyncData: function () {
			    var self = this
			    window.setTimeout(function () {
			      //self.$emit('updateHead')
			    }, 3000)
		  	},
		  	formatFeatures: function(f){
		  		return this.formatArrayWithCommas(f);
		  	},
		  	log: function(network, url){

				this.$ga.event({
					eventCategory: "social-share",
					eventAction: network,
					eventLabel: url
				})
			},
		},

	}


</script>

<style scoped>
	#music-video{
		font-family: fira-sans-condensed,sans-serif;
		font-weight: 700;
		font-style: normal;
	}
	.mv-title{
		margin-bottom:0px;
	}
	.mv-featuring{
		font-family: fira-sans-condensed,sans-serif;
		text-align:center;
		display:inline-block;
		text-transform:capitalize !important;
	}
	.mv-features{
		font-size:;
		text-align:center;
		display:inline-block;
		margin-bottom:0;
		padding-top:0;
	}

	.feature{
		background-color:white;
		color:purple;
		margin-left:5px;
		margin-right:5px;
		padding: 2px 2px 2px 2px;
	}


	.heading{
		line-height:30px;
		padding-top:10px;
		padding-bottom:10px;
	}

	.network{
		border: 1px solid black !important;
	}

	.social-sharing{
		margin-top:15px;
		margin-left:0px;
		margin-right:0px;
	}

</style>