<template>
	<div id="not-found" class="container">
		<Header />
		<h1 class=".heading">Resource Not Found!</h1>
		<p>Sorry, I could not find the resource you were looking for.</p>

	</div>
</template>

<script>
	import Header from './Header.vue'

	export default{
		name: "NotFound",
		components: {
           Header
        }
	}
</script>