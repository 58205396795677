<template>
	<div id="user" class="container">
		<Header />
		<div v-if="dbUser" class="">
			<h1 class="heading">What's up, {{dbUser.displayName}}?</h1>

			<div v-if="dbUser.roles.admin" class="row" style="margin-bottom:20px;">
				<div class="col-sm-4">
					<router-link to="/dash" class="btn btn-heit-purp"><i class="fas fa-toolbox"></i> Dashboard</router-link>
				</div>
			</div>

			<div class="row" id="currentUser">

				<div class="offset-1 col-sm-4">
					<img v-if="dbUser.providerData[avatarIndex].photoURL" :src="dbUser.providerData[avatarIndex].photoURL + '?type=large'" style="width:100%;">
					<img v-else src="../assets/no-user-image.jpg" style="width:100%;">
					<button class="btn btn-heit-gold float-left" @click='logOut' style="margin-top:20px">Log out</button>
				</div>

				<div class="col-sm-6">
					<form @submit.prevent>

						<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Display Name</label>
							<div class="col-sm-9">
								<select class="form-control" v-model="dbUser.displayName">
									<option v-for="pData in dbUser.providerData">{{pData.displayName}}</option>
									<option v-if="dbUser.nickname">{{dbUser.nickname}}</option>
								</select>
							</div>
						</div>

						<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Email</label>
							<div class="col-sm-9">
								<input type="text" class="form-control" id="email" placeholder="Last name" v-model="dbUser.email" disabled>
							</div>
						</div>

						<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Nickname</label>
							<div class="col-sm-9">
								<input type="text" class="form-control" id="nickname" placeholder="nickname" v-model="dbUser.nickname">
							</div>
						</div>

						<!-- <div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Avatar</label>
							<div class="col-sm-9">
								<select class="form-control" @change="changeAvatar()">
									<option v-for="pData in dbUser.providerData" value="">{{pData.photoURL}}</option>
									<option v-for="(pData, index) in dbUser.providerData">Avatar {{index +1}}</option>
								</select>
							</div>
						</div>-->

						<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">First Name</label>
							<div class="col-sm-9">
								<input type="text" class="form-control" id="artist" placeholder="First name" v-model="dbUser.firstName">
							</div>
						</div>

						<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Last Name</label>
							<div class="col-sm-9">
								<input type="text" class="form-control" id="artist" placeholder="Last name" v-model="dbUser.lastName">
							</div>
						</div>

						<!--<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Display Name</label>
							<div class="col-sm-9">
								<select class="form-control">
									<option v-for="pData in dbUser.providerData" value="">{{pData.email}}</option>
								</select>
							</div>
						</div>-->

						<div class="form-group row">
							<label for="" class="col-sm-3 col-form-label">Phone Number</label>
							<div class="col-sm-9">
								<input type="tel" class="form-control" id="artist" placeholder="555-555-5555" v-model="dbUser.phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
							</div>
						</div>

						<button class="btn btn-heit-gold btn-block"  @click="updateUser(dbUser)">Save</i></button>

					</form>

				</div>

			</div>
		</div>
		<!--<pre>{{user}}</pre> -->
	</div>
</template>

<script>
	import Vue from 'vue'
	import Vuex from 'vuex'
	import { db } from '../main'
	import firebase from 'firebase/app'
	import Header from './Header.vue'

	export default {
		name:'user',
		components: {
			Header
		},
		data() {
			return{
				photo: '',
				userId: '',
				name: '',
				email: '',
				user: {},
				avatarIndex: 0,
				updatedUser:{}
			}
		},
		computed: Vuex.mapState(['dbUser']),
		created(){
			this.user = firebase.auth().currentUser;
			if(this.user){
				this.name = this.user.displayName;
				this.email = this.user.email; 
				this.photo = this.user.photoURL; 
				this.userId = this.user.uid;
				this.$store.dispatch('getUser', this.userId);
			}
		},
			
		methods: {
			changeAvatar: function(){
				console.log(event.target.value);
				var index = event.target.value.match(/\d+/)[0] - 1;
				console.log(index);
				this.avatarIndex = index;
			},
			getLastName: function(fullName){
				console.log("getLastName");
				var parts = fullName.split(" ");
				this.updatedUser.firstName = parts.pop();
				this.updatedUser.lastName = parts.join(" ");
				console.log(updatedUser);
			},
			updateUser: function(dbUser){
				console.log(dbUser);
				console.log(this.avatarIndex);

				this.updatedUser = {
					displayName: dbUser.displayName,
					photoURL: dbUser.providerData[this.avatarIndex].photoURL,
					nickname: dbUser.nickname,
					firstName: dbUser.firstName,
					lastName: dbUser.lastName,
					email: dbUser.email,
					phone: dbUser.phone
				}

				console.log("updatedUser.........");
				console.log(this.updatedUser);

				var ref= db.collection('users').doc(dbUser.uid);
				var setWithMerge = ref.set(this.updatedUser, { merge: true }).then(() =>{
					console.log("userUpdated");
					/*this.newVideo = {
						artist:"",
						title:"",
						date:null,
						timestamp:null,
						ytid:"",
						features:[],
						show:false
					}*/
				});
			},
			logOut(){
				firebase.auth().signOut().then(() => {
					console.log("User logged out.");
					this.$router.push('/login');
				}, function(error) {
				 	console.error('Sign Out Error', error);
				});
			}
		}

	};
</script>

<style scoped>
	#currentUser{
		margin-left:auto;
		margin-right:auto;
	}

	form{
		margin-top:10px;
		margin-bottom:10px;
	}
</style>