<template>
	<div id="home" class="">

		<div class="col-12">
			<h3 class="heading">Music Videos</h3>
			<MusicVideosMini :total="4" />
		</div>


		
		<Playlists class="section" />
		<VideosYT />
		<router-view></router-view>

	</div>
</template>

<script>
	import Header from './Header.vue';
	import Playlists from './Playlists.vue';
	import VideosYT from './VideosYT.vue';
	import MusicVideosMini from './mini-components/MusicVideosMini.vue';
	import PrivateVideos from './PrivateVideos.vue'
	import BeatsPlayer from './BeatsPlayer.vue'

	export default {
		name: 'Home',
			components: {
				Header, Playlists, VideosYT,PrivateVideos, MusicVideosMini
			},
			data(){
				return{
					title: 'Catch Ya On The B-Side!'
				}
			},
			head: {
				title: {
					inner: "HeitMusic by Heit The Great",
			     	complement: 'Hip Hop & Rap Music History'
			    }
			},
			mounted(){
				document.dispatchEvent(new Event('app.rendered'));
			}
	}


</script>