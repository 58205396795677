export const firebaseConfig = {
	apiKey: "AIzaSyAlPuLdJEomLa_TjwbJBcDxkSyynkqFsxo",
	authDomain: "heitmusicweb.firebaseapp.com",
	databaseURL: "https://heitmusicweb.firebaseio.com",
	projectId: "heitmusicweb",
	storageBucket: "heitmusicweb.appspot.com",
	messagingSenderId: "235677195164",
	appId: "1:235677195164:web:da7d03d2696894af"
};

