<template>
	<div class="container">
		<h1 class="heading">YouTube Videos</h1>
		<div class="row">
			<div class="col-md-6 col-lg-6 col-xl-6 d-flex align-items-stretch" v-for="index in videosToShow" v-if="index < videos.length">
				
				<div class="card ytvid">
					<!--<div class='embed-container'><iframe :src="'https://www.youtube.com/embed/' + videos[index].id.videoId" frameborder='0' allowfullscreen></iframe></div>-->
					<img class="card-img-top" :src="'https://img.youtube.com/vi/' + videos[index - 1].id.videoId + '/maxresdefault.jpg'" data-cat="thumbnail" @click="log" :alt="videos[index -1].snippet.title">
					<div class="card-body d-flex flex-column">
						<h4 v-html="videos[index -1].snippet.title" class="card-title mt-auto"></h4>
						<p class="card-text mt-auto">{{videos[index -1].snippet.description}}</p>
						<a :href="'https://www.youtube.com/watch?v=' + videos[index -1].id.videoId" target="_blank" class="btn btn-block btn-heit-purp mt-auto" data-cat="watch-button" @click="log" :alt="videos[index -1].snippet.title">Watch on YouTube</a>
					</div>
				</div>

			</div>
			<div class="load-more btn btn-block btn-outline-secondary" data-cat="button" @click="log($event, 'Show More')" v-if="videosToShow <= 19" alt="Load More">Load More Videos</div>
			<div class="load-more btn btn-block btn-outline-secondary" data-cat="button" @click="log($event, 'Show Less')" v-if="videosToShow == 20" alt="Show Less">Show Less</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default{
		name: "VideosYT",
		data(){
			return{
				videos: [],
				videosToShow: 4
			}
		},
		mounted () {
			axios
			  .get('https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCSAFgIUqczX602gsWxf_mvw&maxResults=21&order=date&type=video&key=AIzaSyAlPuLdJEomLa_TjwbJBcDxkSyynkqFsxo')
			  .then(response => (this.videos = response.data.items))
		},
		methods: {
			log: function(e, context){
				//console.log(e.target.getAttribute('alt'));

				if(context == "Show More"){
					this.videosToShow +=2;
				}else if(context =="Show Less"){
					this.videosToShow = 4;
				}

				this.$ga.event({
					eventCategory: e.target.getAttribute('data-cat'),
					eventAction: 'click',
					eventLabel: e.target.getAttribute('alt')
				})
			}
		}
	}
</script>

<style scoped>
	.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
	.ytvid{
		margin-bottom: 25px;
	}

	.load-more{
		margin-bottom:25px;
	}

	.ytvid{
		color:#52194D;
	}

	p{
		text-align:left;
	}


</style>