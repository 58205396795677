<template>
	<div id="music-videos">
		<div class="container">
			<h1 class="heading">Music Videos</h1>

			<div class="row">
				<div class="input-group input-group-lg col-12 col-sm-7" id="video-serach">

					  <div class="input-group-prepend">
						<span class="input-group-text" id="inputGroup-sizing-lg"><i class="fas fa-search"></i></span>
					  </div>
					  <input type="text" v-model="search" class="form-control" aria-label="Search" aria-describedby="inputGroup-sizing-sm">
				</div>

				<div class="col-12 col-sm-5 text-center" style="padding:0px;">
					<div class="btn-group btn-group-toggle" data-toggle="buttons">
						<!--<h5 class="label-heading align-bottom">Filter: </h5>-->

						<label class="btn btn-heit-purp active" @click="filterGenre('all')">
							<input type="radio" name="categories" checked>All Videos
						</label>

						<label class="btn btn-heit-purp" @click="filterGenre('rap')">
							<input type="radio" name="categories">Rap Videos
						</label>

						<label class="btn btn-heit-purp" @click="filterGenre('rb')">
							<input type="radio" name="categories">R&B Videos
						</label>

					</div>
				</div>

			</div>


			<div class="row">
				<div class="col-md-6 col-lg-6 col-xl-6 video" v-for="video in searchedVideos" v-if="video.show">
					<div class="card ytvid">
						<!--<h4 class="new"><span class="badge badge-secondary" v-if="isNew()">{{video.genre}}</span></h4>-->
						<h4 class="genre"><span class="badge badge-secondary" @click="filterGenre(video.genre)">{{video.genre}}</span></h4>
						<!-- <div class='card-img-top embed-container'><iframe :src="'https://www.youtube.com/embed/' + video.ytid" frameborder='0' allowfullscreen></iframe></div> -->
						<img class="card-img-top" :src="'https://img.youtube.com/vi/' + video.ytid + '/maxresdefault.jpg'" data-cat="thumbnail" @click="log" :alt="video.title">
						<div class="card-body d-flex flex-column">
							<h4 class="card-title mt-auto video-title">{{video.title}}</h4>
							<h5> {{video.artist}} <span v-if="video.features"> feat. </span><span v-if="video.features" v-html="formatArrayWithCommas(video.features)"></span></h5>
							<p class="card-text mt-auto">Music video by <span v-html="video.artist"></span> released on <span v-html="formatDate(video.timestamp.seconds)"></span>.</p>
							<button class="btn btn-block btn-heit-purp mt-auto" data-cat="watch-button" @click="viewVideo($event)" :alt="video.title" :data-id="video.id">View Video</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Vuex from 'vuex'
	import { db } from '../main'
	import Header from './Header.vue'
	import mixin from '../helpers/mixin.js'

	export default {
		name: "MusicVideos",
		components: { Header},
		mixins: [mixin],
		data(){
			return{
				search:'',
				genre: 'all'
			}
		},
		head: {
			title: {
		     	inner: 'Music Videos'
		    },
		    meta: [
				{ name: 'application-name', content: 'Heit Music Web' },
				{ name: 'description', content: "New Rap/Hip Hop and R&B music videos for the 1990's  & 1980's babies.", id: "description" }, // id to replace intead of create element

				// Facebook / Open Graph
				{ property: 'og:title', content: "Music Videos | Heit The Great", id: "og-title"},
				{ property: 'og:description', content: "New Rap/Hip Hop and R&B music videos for the 1990's  & 1980's babies.", id: "og-description"},

				{ property: 'og:image', content: "https://www.heitmusic.com/images/HTG-New-Rap-R&B-Music-Videos.png", id: "og-image"},
				{ property: 'og:url', content: "https://www.heitmusic.com/music-videos/", id: "og-url"},
				
				// with shorthand
				//{ p: 'og:image', c: 'https://example.com/image.jpg' }
		    ]
		},
		computed: {
			...Vuex.mapState(['musicVideos']),
			searchedVideos: function(){
				return this.musicVideos.filter((video) => {

					var searched = video.title.match(this.search) || video.title.toUpperCase().match(this.search) || video.title.toLowerCase().match(this.search)
						|| video.artist.match(this.search) || video.artist.toUpperCase().match(this.search) || video.artist.toLowerCase().match(this.search)
						|| video.id.match(this.search) || video.id.toUpperCase().match(this.search) || video.id.toLowerCase().match(this.search);

					var isRB, isRap;
					if(video.genre == "R&B"){
						video.isRB = true;
					}

					if(video.genre == "Rap"){
						video.isRap = true;
					}

					if (searched && this.genre == "rb"){
						return video.isRB;
					}else if(searched && this.genre =="rap"){
						return video.isRap;
					}else if(searched && this.genre == "all"){
						return true;
					}
				})
			}
		},
		mounted(){
			//document.dispatchEvent(new Event('app.rendered'));
		},
		created(){
			this.$store.dispatch('setMusicVideosRef', db.collection('musicvideos')).then(mv =>{
				//this.asyncCall(5000);
			});
			//this.asyncCall(5000);
		},
		methods: {
			log: function(e, context){
				//console.log(e.target.getAttribute('alt'));

				if(context == "Show More"){
					this.videosToShow +=2;
				}else if(context =="Show Less"){
					this.videosToShow = 4;
				}

				this.$ga.event({
					eventCategory: e.target.getAttribute('data-cat'),
					eventAction: 'click',
					eventLabel: e.target.getAttribute('alt')
				})
			},
			resolveAfter: function(secs){
				return new Promise(resolve => {
				    setTimeout(() => {
				      	resolve('resolved');
				    }, secs);
				});
			},
			asyncCall: async function(secs) {
			  	//console.log('calling');
			  	var result = await this.resolveAfter(secs);
			  	//console.log(result);
			  	document.dispatchEvent(new Event('app.rendered'));
			  	// expected output: 'resolved'
			},
			viewVideo: function(e){
				this.$ga.event({
					eventCategory: e.target.getAttribute('data-cat'),
					eventAction: 'click',
					eventLabel: e.target.getAttribute('alt')
				})
				var id = e.target.getAttribute('data-id');
				this.$router.push('/music-videos/' + id);
			},
			filterGenre: function(gen){
				var g = gen.replace(/[^a-zA-Z ]/g, "").toLowerCase();

				this.genre = g;
				//console.log(this.genre);
			}
		}
	}
</script>

<style>
	.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
	.ytvid{
		margin-bottom: 25px;
	}
	.ytvid{
		
	}

	p{
		text-align:left;
	}

	.card-img-top {
		width: 100%;
		max-height:302.625px;
	}

	.btn-group{
		margin-top:auto;
		margin-bottom:auto;
	}

	.video{
		margin-top:15px;
	}

	#inputGroup-sizing-lg{
		background-color:#EBA90D;
		color:#52194D;
	}

	.card-title{
		display:inline-block;
	}

	.genre{
		position:absolute;
		right:4px;
		padding:0;
		margin:0;
	}

	.badge{
		background-color:#52194D;
		broder-radius:0px;
		cursor: pointer;
	}

	.video-title{

	}

</style>